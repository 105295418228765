"use client";

import { ToastProvider, ToastViewport } from "@/app/components/ui/toast";
import { AuthContextProvider } from "@/contexts/AuthContext";
import { CollectionContextProvider } from "@/contexts/CollectionContext";
import { HeaderContextProvider } from "@/contexts/HeaderContext";
import { MenuContextProvider } from "@/contexts/MenuContext";
import { SearchContextProvider } from "@/contexts/SearchContext";
import { WebsitesContextProvider } from "@/contexts/WebsitesContext";
import * as Tooltip from "@radix-ui/react-tooltip";
import { ProfilesContextProvider } from "./ProfilesContext";

const ContextProviders = ({ children }: { children: React.ReactNode }) => {
  return (
    <AuthContextProvider>
      <MenuContextProvider>
        <SearchContextProvider>
          <WebsitesContextProvider>
            <ProfilesContextProvider>
              <CollectionContextProvider>
                <HeaderContextProvider>
                  <ToastProvider>
                    <Tooltip.Provider>
                      {children}
                      <ToastViewport />
                    </Tooltip.Provider>
                  </ToastProvider>
                </HeaderContextProvider>
              </CollectionContextProvider>
            </ProfilesContextProvider>
          </WebsitesContextProvider>
        </SearchContextProvider>
      </MenuContextProvider>
    </AuthContextProvider>
  );
};

export default ContextProviders;
