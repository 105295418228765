"use client";

import clsx from "clsx";
import Link from "next/link";
import { useSelectedLayoutSegment } from "next/navigation";
import { ReactNode } from "react";

export default function AuthHeading() {
  const segment = useSelectedLayoutSegment();

  const _Wrapper = ({ children }: { children: ReactNode }) => {
    return <h1 className="mt-6 text-center main-heading">{children}</h1>;
  };

  if (segment === "signup") {
    return <_Wrapper>Create a new account</_Wrapper>;
  }

  if (segment === "signin") {
    return (
      <div className={clsx("space-y-1")}>
        <_Wrapper>Sign in to your account</_Wrapper>
        <div
          className={clsx(
            "text-center text-zinc-700 transition ease-out hover:text-zinc-900"
          )}
        >
          Or{" "}
          <Link
            href={"/signup"}
            className={clsx("text-zinc-900 underline underline-offset-2")}
          >
            sign up
          </Link>{" "}
          if you're not a member yet
        </div>
      </div>
    );
  }

  if (segment === "forgotten") {
    return <_Wrapper>Reset your password</_Wrapper>;
  }

  if (segment === "set-password") {
    return <_Wrapper>Set a new password</_Wrapper>;
  }

  return null;
}
