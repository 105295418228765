import type { ButtonProps } from "@/app/components/ui/button";
import { Button } from "@/app/components/ui/button";
import clsx from "clsx";
import { ArrowUpRight } from "lucide-react";
import Link from "next/link";

const WebsiteUrlButton = ({
  website,
  className,
  size = "icon",
  variant = "secondary",
}: {
  website: {
    url: string;
    title: string;
  };
  className?: string;
  size?: ButtonProps["size"];
  variant?: ButtonProps["variant"];
}) => {
  return (
    <Button variant={variant} rounded size={size} asChild>
      <Link
        href={`${website.url}${website.url?.includes("?") ? "&" : "?"}ref=siteinspire`}
        className={clsx("WebsiteUrlButton", className)}
        target="_blank"
        rel="noreferrer"
        aria-label={`Visit ${website.title} — ${website.url}`}
      >
        <ArrowUpRight className={clsx("size-4 stroke-[1.5]")} />
      </Link>
    </Button>
  );
};

export default WebsiteUrlButton;
