"use client";

import Screengrab from "@/app/components/Screengrab";
import { Button } from "@/app/components/ui/button";
import type { FetchedWebsite } from "@/app/main/websites/services/fetchWebsites";
import clsx from "clsx";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import { EmblaCarouselType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import Link from "next/link";
import { useCallback, useEffect, useState } from "react";

dayjs.extend(relative);

const Slideshow = ({
  websites = [],
  onSlideChange = (index: number) => null,
}: {
  websites: FetchedWebsite[];
  onSlideChange?: (index: number) => void;
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollTo = useCallback(
    (index: number) => {
      emblaApi?.scrollTo(index);
    },
    [emblaApi]
  );

  const logSlidesInView = useCallback(
    (emblaApi: EmblaCarouselType) => {
      const currentIndex = emblaApi.selectedScrollSnap();
      setSelectedIndex(currentIndex);
      onSlideChange(currentIndex);
    },
    [onSlideChange]
  );

  useEffect(() => {
    if (emblaApi) emblaApi.on("select", logSlidesInView);
  }, [emblaApi, logSlidesInView]);

  const selectedWebsite = websites[selectedIndex] || null;

  return (
    <div className={clsx("pb-14 lg:pb-14 overflow-x-hidden w-full relative")}>
      <div
        ref={emblaRef}
        className={clsx(
          "Slideshow",
          "px-6 lg:pl-[calc((100%/3)+2rem)] overflow-hidden relative"
        )}
      >
        <div className={clsx("flex space-x-6 lg:space-x-8")}>
          {websites.map((website, _index) => {
            return (
              <div
                key={website.id}
                className={clsx(
                  "flex-[0_0_calc(100%)] lg:flex-[0_0_75%] flex flex-col @container"
                )}
              >
                <Link href={`/website/${website.id}-${website.slug}`}>
                  <Screengrab
                    priority={_index === 0}
                    website={website}
                    inverse
                    slideshowContext
                  />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <DotNavigation
        websiteIds={websites.map((website) => website.id)}
        selectedIndex={selectedIndex}
        scrollTo={scrollTo}
      />
      <div
        className={clsx("absolute bottom-0 right-6 flex items-center gap-4")}
      >
        {selectedWebsite && (
          <Link
            href={`/website/${selectedWebsite.id}-${selectedWebsite.slug}`}
            className="text-sm text-zinc-600 whitespace-nowrap overflow-ellipsis overflow-x-hidden max-w-[calc(100vw-14.5rem)]"
          >
            {selectedWebsite?.title}
          </Link>
        )}
        <NavButtons
          scrollNext={() => emblaApi?.scrollNext?.()}
          scrollPrev={() => emblaApi?.scrollPrev?.()}
        />
      </div>
    </div>
  );
};

const DotNavigation = ({
  websiteIds = [],
  selectedIndex = 0,
  scrollTo,
}: {
  websiteIds: number[];
  selectedIndex: number;
  scrollTo: (index: number) => void;
}) => {
  return (
    <div
      className={clsx(
        "absolute flex justify-start px-6 bottom-0 left-0 right-0 lg:pl-[calc(33.333%+2rem)]"
      )}
    >
      <div
        className={clsx(
          "flex rounded-full justify-center gap-2 h-8 items-center"
        )}
      >
        {websiteIds.map((_, index) => (
          <button
            key={index}
            onClick={() => scrollTo(index)}
            className={clsx("size-1 rounded-full transition ease-out", {
              "bg-zinc-950": selectedIndex === index,
              "bg-zinc-300 hover:bg-white/75": selectedIndex !== index,
            })}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

const NavButtons = ({
  scrollPrev,
  scrollNext,
}: {
  scrollPrev: EmblaCarouselType["scrollPrev"];
  scrollNext: EmblaCarouselType["scrollNext"];
}) => {
  return (
    <div className="flex space-x-2">
      <Button
        variant="circular"
        size="iconSm"
        className={clsx("bg-white")}
        onClick={() => scrollPrev()}
      >
        <ChevronLeft className={clsx("size-4 stroke-[1.5]")} />
      </Button>
      <Button
        onClick={() => scrollNext()}
        variant="circular"
        size="iconSm"
        className={clsx("bg-white")}
      >
        <ChevronRight className={clsx("size-4 stroke-[1.5]")} />
      </Button>
    </div>
  );
};

export default Slideshow;
