"use client";

import fetchWebsitesCollectionState from "@/app/(main)/websites/actions/fetchWebsitesCollectionState";
import Ghost from "@/app/components/Ghost";
import fetchWebsitesAction from "@/app/main/websites/actions/fetchWebsitesAction";
import WebsiteCard from "@/app/main/websites/components/WebsiteCard";
import type { FetchedWebsite } from "@/app/main/websites/services/fetchWebsites";
import { useAuthContext } from "@/contexts/AuthContext";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { Loader2 } from "lucide-react";
import { Fragment, useCallback, useMemo, useRef } from "react";

const Websites = ({
  initialWebsites = [],
  initialOffset = 0,
  limit = 24,
  showGhost = true,
  highlightGhost = false,
  categorySlugs = [],
  selected = false,
}: {
  initialWebsites?: FetchedWebsite[];
  initialOffset?: number;
  limit?: number;
  showGhost?: boolean;
  highlightGhost?: boolean;
  categorySlugs?: string[];
  selected?: boolean;
}) => {
  const ref = useRef(null);
  const { status, user } = useAuthContext();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [
        "websites",
        {
          categorySlugs,
          selected,
          initialWebsiteIds: initialWebsites.map((w) => w.id),
        },
      ],
      queryFn: async ({ pageParam = initialOffset }) => {
        const result = await fetchWebsitesAction({
          limit,
          offset: pageParam,
          categorySlugs,
          selected,
        });
        return result as { websites: FetchedWebsite[] };
      },
      initialPageParam: initialOffset,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.websites.length < limit) return undefined;
        return initialOffset + allPages.length * limit;
      },
      initialData: initialWebsites.length
        ? {
            pages: [
              {
                websites: initialWebsites,
              },
            ],
            pageParams: [initialOffset],
          }
        : undefined,
    });

  const { data: websitesCollectionsState } = useQuery({
    queryKey: ["websitesCollectionsState", user?.id],
    queryFn: async () => {
      if (!user) return [];

      // Only fetch the IDs
      const websiteIds = await fetchWebsitesCollectionState({
        userId: user.id,
      });

      // Return just the array of IDs
      return websiteIds;
    },
    enabled: status === "authenticated",
  });

  const websites = useMemo(() => {
    return data?.pages.flatMap((page) => page.websites) ?? [];
  }, [data]);

  const ghostVisibility = useCallback(
    (index: number) => {
      const baseClass =
        "col-span-12 sm:col-span-6 lg:col-span-4 3xl:col-span-3";
      const visibilityClasses = [
        "hidden sm:block lg:hidden",
        "hidden lg:block 3xl:hidden",
        "hidden 3xl:block",
      ];
      const highlightClasses = ["bg-yellow-500", "bg-blue-500", "bg-green-500"];

      if (index >= 0 && index < 3) {
        return clsx(
          baseClass,
          visibilityClasses[index],
          highlightGhost && highlightClasses[index]
        );
      }
      return "hidden";
    },
    [highlightGhost]
  );

  const memoizedWebsiteCards = useMemo(() => {
    return websites.map((website, _index) => (
      <Fragment key={website.id}>
        <div
          className={clsx(
            "col-span-12 sm:col-span-6 lg:col-span-4 3xl:col-span-3"
          )}
        >
          <WebsiteCard website={website} />
        </div>

        {showGhost && (
          <div key={`${website.id}-ghost`} className={ghostVisibility(_index)}>
            <Ghost />
          </div>
        )}
      </Fragment>
    ));
  }, [websites, showGhost, ghostVisibility, websitesCollectionsState]);

  // When using the data, you can check if a website ID is in the array
  const isWebsiteInCollection = (websiteId: number) => {
    return websitesCollectionsState?.includes(websiteId) ?? false;
  };

  return (
    <div className={clsx("Websites", "px-6 lg:px-8")}>
      <div
        className={clsx(
          "Websites__content",
          "grid grid-cols-12 gap-x-6 gap-y-6 lg:gap-x-16 lg:gap-y-12 2xl:gap-x-16"
        )}
      >
        {memoizedWebsiteCards}
        {hasNextPage && (
          <button
            className={clsx(
              "col-span-12 flex items-start sm:col-span-6 lg:col-span-4 3xl:col-span-3"
            )}
            onClick={() => fetchNextPage()}
            ref={ref}
          >
            <div className={clsx("block w-full rounded border bg-white p-6")}>
              <div
                className={clsx(
                  "relative flex aspect-[16/10] items-center justify-center overflow-hidden rounded-sm"
                )}
              >
                <div>{isFetchingNextPage ? "Loading more" : "Load more"}</div>
                {isFetchingNextPage && (
                  <div className={clsx("ml-3")}>
                    <Loader2
                      className={clsx("size-4 stroke-[1.5] animate-spin")}
                    />
                  </div>
                )}
              </div>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

export default Websites;
