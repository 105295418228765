"use client";

import ConfigureFathom from "@/app/components/ConfigureFathom";
import ConfigurePostHog from "@/app/components/ConfigurePostHog";
import { ToastProvider, ToastViewport } from "@/app/components/ui/toast";
import { CSPostHogProvider } from "@/providers/posthog";
import * as Tooltip from "@radix-ui/react-tooltip";

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <CSPostHogProvider>
      <ToastProvider>
        <Tooltip.Provider>
          {children}
          <ToastViewport />
          <ConfigurePostHog />
          <ConfigureFathom />
        </Tooltip.Provider>
      </ToastProvider>
    </CSPostHogProvider>
  );
};

export default Providers;
