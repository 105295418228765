"use client";

import {
  Filter,
  FilterLink,
  FilterShowMoreButton,
  FilterTabContent,
} from "@/app/components/Filter";
import { Button } from "@/app/components/ui/button";
import WebflowCircle from "@/app/components/WebflowCircle";
import fetchCategoriesAction from "@/app/main/categories/actions/fetchAllCategoriesWithCountsAction";
import { useWebsitesContext } from "@/contexts/WebsitesContext";
import filterContexts from "@/lib/filterContexts";
import clsx from "clsx";
import { X } from "lucide-react";
import Link from "next/link";
import { useSelectedLayoutSegments } from "next/navigation";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";

const previewCount = 14;

interface Category {
  slug: string;
  name: string;
  count: number;
  context: string;
}

const WebsiteFilterWrapper = ({ categories }: { categories: Category[] }) => {
  return (
    <Suspense>
      <WebsiteFilter categories={categories} />
    </Suspense>
  );
};

const WebsiteFilter = ({
  categories: initialCategories,
}: {
  categories: Category[];
}) => {
  const [categories, setCategories] = useState(initialCategories);
  const { selectedContext, setSelectedContext, isFilterOpen, setIsFilterOpen } =
    useWebsitesContext();
  const segments = useSelectedLayoutSegments();
  const previousSlugsRef = useRef("");
  const popularCategoriesRef = useRef<Category[]>([]);

  const selectedCategorySlugs = useMemo(() => {
    if (!segments.length) return [];
    if (segments[0] === "categories") {
      return segments.slice(1).flatMap((segment) => segment.split("/"));
    }
    if (segments[0] === "category") {
      return segments[1] ? [segments[1]] : [];
    }
    return [];
  }, [segments]);

  useEffect(() => {
    const currentSlugsKey = selectedCategorySlugs.sort().join(",");
    if (currentSlugsKey === previousSlugsRef.current) return;

    previousSlugsRef.current = currentSlugsKey;
    if (selectedCategorySlugs.length === 0) {
      setCategories(initialCategories);
      return;
    }

    let mounted = true;
    const fetchCategories = async () => {
      try {
        const updatedCategories = await fetchCategoriesAction({
          selectedCategorySlugs,
        });
        if (mounted) {
          setCategories(updatedCategories);
        }
      } catch (error) {
        console.error("Failed to update category counts:", error);
      }
    };

    fetchCategories();
    return () => {
      mounted = false;
    };
  }, [selectedCategorySlugs, initialCategories]);

  const selectedCategories = useMemo(
    () =>
      categories.filter((category) =>
        selectedCategorySlugs.includes(category.slug)
      ),
    [categories, selectedCategorySlugs]
  );

  const popularCategories = useMemo(() => {
    if (popularCategoriesRef.current.length === 0) {
      popularCategoriesRef.current = [...categories]
        .sort((a, b) => {
          const countDiff = b.count - a.count;
          if (countDiff !== 0) return countDiff;
          return a.name.localeCompare(b.name);
        })
        .slice(0, previewCount);
    }

    return popularCategoriesRef.current.map((popularCategory) => {
      const updatedCategory = categories.find(
        (c) => c.slug === popularCategory.slug
      );
      return updatedCategory || popularCategory;
    });
  }, [categories]);

  const categoriesByContext = useMemo(() => {
    const result: Record<string, Category[]> = {};
    filterContexts.forEach((context) => {
      result[context.key] = categories.filter(
        (category) => category.context === context.key
      );
    });
    return result;
  }, [categories]);

  const selectedItems = useMemo(() => {
    if (selectedCategories.length === 0) return [];

    return selectedCategories.map((category) => {
      const href = (() => {
        const updatedSlugs = selectedCategorySlugs.filter(
          (slug) => slug !== category.slug
        );
        if (updatedSlugs.length === 0) return "/websites";
        if (updatedSlugs.length === 1)
          return `/websites/category/${updatedSlugs[0]}`;
        return `/websites/categories/${updatedSlugs.join("/")}`;
      })();

      return {
        id: category.slug,
        label: category.name,
        href,
      };
    });
  }, [selectedCategories, selectedCategorySlugs]);

  return (
    <Filter
      contexts={[
        { key: "popular", name: "Popular Categories" },
        ...filterContexts,
      ]}
      selectedContext={selectedContext}
      onContextChange={setSelectedContext}
      defaultOpen={isFilterOpen}
      selectedItems={selectedItems}
    >
      <FilterTabContent value="popular">
        <CategoryList
          categories={popularCategories}
          selectedCategorySlugs={selectedCategorySlugs}
        />
      </FilterTabContent>
      {filterContexts.map((context) => (
        <FilterTabContent key={context.key} value={context.key}>
          <CategoryList
            categories={categoriesByContext[context.key] ?? []}
            selectedCategorySlugs={selectedCategorySlugs}
          />
        </FilterTabContent>
      ))}
    </Filter>
  );
};

const CategoryList = ({
  categories,
  selectedCategorySlugs,
}: {
  categories: Category[];
  selectedCategorySlugs: string[];
}) => {
  const [showAll, setShowAll] = useState(false);

  const displayCategories = useMemo(() => {
    return showAll ? categories : categories.slice(0, previewCount);
  }, [categories, showAll]);

  return (
    <>
      {displayCategories.map((category) => (
        <CategoryLink
          key={category.slug}
          category={category}
          selectedCategorySlugs={selectedCategorySlugs}
        />
      ))}
      {categories.length > previewCount && (
        <FilterShowMoreButton
          onClick={() => setShowAll(!showAll)}
          showingAll={showAll}
        />
      )}
    </>
  );
};

const CategoryLink = ({
  category,
  selectedCategorySlugs,
}: {
  category: Category;
  selectedCategorySlugs: string[];
}) => {
  const isSelected = selectedCategorySlugs.includes(category.slug);

  const href = useMemo(() => {
    if (selectedCategorySlugs.includes(category.slug)) {
      const updatedSlugs = selectedCategorySlugs.filter(
        (slug) => slug !== category.slug
      );
      if (updatedSlugs.length === 0) return "/websites";
      if (updatedSlugs.length === 1)
        return `/websites/category/${updatedSlugs[0]}`;
      return `/websites/categories/${updatedSlugs.join("/")}`;
    }

    const updatedSlugs = [...selectedCategorySlugs, category.slug];
    if (updatedSlugs.length === 1)
      return `/websites/category/${updatedSlugs[0]}`;
    return `/websites/categories/${updatedSlugs.join("/")}`;
  }, [category.slug, selectedCategorySlugs]);

  return (
    <FilterLink
      href={href}
      isSelected={isSelected}
      disabled={category.count === 0}
      count={category.count}
      icon={category.slug === "webflow" ? <WebflowCircle /> : undefined}
    >
      {category.name}
    </FilterLink>
  );
};

export default WebsiteFilterWrapper;
