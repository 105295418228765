"use client";

import Slideshow from "@/app/components/Slideshow";
import type { FetchedWebsite } from "@/app/main/websites/services/fetchWebsites";
import clsx from "clsx";
import { AnimatePresence, motion } from "motion/react";
import { useEffect, useState } from "react";

const DURATION = 0.5;

const HeroDesktop = ({ websites }: { websites: FetchedWebsite[] }) => {
  const [showStandfirst, setShowStandfirst] = useState(true);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const handleSlideChange = (index: number) => {
    setShowStandfirst(index === 0);
  };

  return (
    <div className={clsx("relative hidden pt-28 lg:block")}>
      <div className={clsx("absolute px-8 py-8")}>
        <AnimatePresence>
          {showStandfirst && (
            <motion.h1
              className={clsx("py-8 text-3xl 3xl:text-4xl")}
              initial={{
                opacity: 0,
                x: isMounted ? "-1rem" : "0rem",
              }}
              animate={{
                opacity: 1,
                x: "0rem",
                transition: { duration: DURATION, ease: "easeOut" },
              }}
              exit={{
                opacity: 0,
                x: "-2rem",
                transition: { duration: DURATION, ease: "easeIn" },
              }}
            >
              A showcase of the web's
              <br />
              finest design + talent
            </motion.h1>
          )}
        </AnimatePresence>
      </div>
      <div className={clsx("py-12")}>
        <Slideshow websites={websites} onSlideChange={handleSlideChange} />
      </div>
    </div>
  );
};

export default HeroDesktop;
