"use client";

import WebflowCircle from "@/app/components/WebflowCircle";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import Link from "next/link";
import { z } from "zod";

type WebflowTokenProps = {
  captionOnHover?: boolean;
  websiteId: number;
  className?: string;
  showCaption?: boolean;
};

const responseSchema = z.array(z.coerce.number());

const WebflowToken = ({
  captionOnHover = false,
  websiteId,
  className = "",
  showCaption = true,
}: WebflowTokenProps) => {
  const { data: webflowWebsiteIds } = useQuery({
    queryKey: ["webflow-website-ids"],
    queryFn: async () => {
      const response = await fetch("/categories/webflow/websites/ids");
      const result = await response.json();
      return responseSchema.parse(result);
    },
    staleTime: 1000 * 60 * 60, // 1 hour
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  if (!webflowWebsiteIds || !webflowWebsiteIds.includes(websiteId)) {
    return null;
  }

  return (
    <Link
      className={clsx("WebflowToken", "group flex items-center", className)}
      href={`https://webflow.com/?utm_source=siteinspire&utm_medium=referral&utm_campaign=partnerships`}
      target="_blank"
      rel="noreferrer"
    >
      <WebflowCircle />

      {showCaption && (
        <div
          className={clsx("hidden whitespace-nowrap pl-2 lg:block", {
            "opacity-0 transition ease-out group-hover:opacity-100":
              captionOnHover,
          })}
        >
          Made with Webflow
        </div>
      )}
    </Link>
  );
};

export default WebflowToken;
