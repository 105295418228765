import(/* webpackMode: "eager" */ "/vercel/path0/client/node_modules/.pnpm/next@15.1.6_@babel+core@7.24.5_@playwright+test@1.50.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/app/components/HeaderMobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/app/components/Logotype.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/app/components/Menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/app/components/NewsletterForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/app/components/PrimaryNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/app/components/UserMenu.tsx");
