import { Badge } from "@/app/components/ui/badge";
import clsx from "clsx";
import Link from "next/link";

const ProfileListItem = ({
  profile,
}: {
  profile: {
    name: string;
    slug: string;
    id: number;
    approvedCreditsCount: number;
    addresses?: {
      id: number;
      cityName: string;
    }[];
  };
}) => {
  return (
    <Link href={`/profile/${profile.id}-${profile.slug}`}>
      <div className={clsx("px-6 lg:px-8 py-4")}>
        <div>
          <div className={clsx("flex items-center space-x-1.5")}>
            <div>{profile.name}</div>
            <Badge variant="secondary">{profile.approvedCreditsCount}</Badge>
          </div>
          {profile?.addresses?.map(
            (address: { id: number; cityName: string }) => {
              return (
                <div key={address.id} className={clsx("text-zinc-500 text-xs")}>
                  {address.cityName}
                </div>
              );
            }
          )}
        </div>
      </div>
    </Link>
  );
};

export default ProfileListItem;
