"use client";

import { Button } from "@/app/components/ui/button";
import { Input } from "@/app/components/ui/input";
import createCollection from "@/app/main/collections/actions/createCollection";
import { useAuthContext } from "@/contexts/AuthContext";
import { useToast } from "@/hooks/use-toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { nanoid } from "nanoid";
import { useState } from "react";

type CollectionState = {
  title: string | null;
  id: number;
  errors?: Record<string, string[]>;
};

type Props = {
  websiteId?: number | null;
  onCreate?: (newCollection: CollectionState) => void;
};

const CollectionForm = ({ websiteId = null, onCreate = () => {} }: Props) => {
  const [formKey, setFormKey] = useState(nanoid());
  const [title, setTitle] = useState("");
  const { toast } = useToast();
  const { user } = useAuthContext();

  const createMutation = useMutation({
    mutationFn: async () => {
      if (!user || !title.trim()) return null;
      return createCollection(user.id, {
        title,
        ...(websiteId ? { websiteId } : {}),
      });
    },
    onSuccess: (result) => {
      if (result?.id) {
        toast({
          title: "Your collection has been created",
        });
        onCreate(result as CollectionState);
        setFormKey(nanoid());
        setTitle("");
      }
    },
    onError: () => {
      toast({
        title: "Failed to create collection",
        variant: "destructive",
      });
    },
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    createMutation.mutate();
  };

  return (
    <form
      key={formKey}
      className={clsx("flex items-center w-full")}
      onSubmit={handleSubmit}
    >
      <Input
        type="text"
        placeholder="Create a new collection"
        className="focus:ring-0"
        required
        autoFocus={false}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <Button
        type="submit"
        disabled={!title.trim() || createMutation.isPending}
        className="ml-2"
        rounded={false}
      >
        {createMutation.isPending ? "Saving..." : "Save"}
      </Button>
    </form>
  );
};

export default CollectionForm;
