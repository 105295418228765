"use client";

import { Button } from "@/app/components/ui/button";
import { ScrollArea, ScrollBar } from "@/app/components/ui/scroll-area";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/app/components/ui/tabs";
import clsx from "clsx";
import { ChevronDown, ChevronUp, X } from "lucide-react";
import Link from "next/link";
import { createContext, ReactNode, useContext, useState } from "react";

type FilterContext = {
  key: string;
  name: string;
};

type SelectedItem = {
  id: string;
  label: ReactNode;
  href?: string;
};

type FilterProps = {
  contexts: FilterContext[];
  selectedContext: string;
  onContextChange: (context: string) => void;
  children?: ReactNode;
  selectedItems?: SelectedItem[];
  defaultOpen?: boolean;
};

type FilterLinkProps = {
  href: string;
  isSelected?: boolean;
  disabled?: boolean;
  count?: number;
  icon?: ReactNode;
  children: ReactNode;
};

const linkClassName =
  "mr-4 inline-flex space-x-1 py-0.5 text-base lg:py-1 md:text-lg lg:text-xl";

type FilterContextType = {
  isFilterOpen: boolean;
  setIsFilterOpen: (value: boolean) => void;
};

const FilterContext = createContext<FilterContextType | undefined>(undefined);

const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilterContext must be used within a FilterProvider");
  }
  return context;
};

export const Filter = ({
  contexts,
  selectedContext,
  onContextChange,
  children,
  selectedItems = [],
  defaultOpen = false,
}: FilterProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(defaultOpen);
  const Icon = isFilterOpen ? ChevronUp : ChevronDown;

  return (
    <FilterContext.Provider value={{ isFilterOpen, setIsFilterOpen }}>
      <div className={clsx("Filter", "flex justify-end overflow-hidden")}>
        <div
          className={clsx(
            "Filter__container",
            "w-full lg:w-2/3 relative overflow-hidden"
          )}
        >
          <div
            className={clsx(
              "Filter__toggleButton",
              "absolute right-6 top-1 z-20"
            )}
          >
            <Button
              variant="secondary"
              size="iconSm"
              rounded
              onClick={() => setIsFilterOpen((prev) => !prev)}
              aria-label="Toggle filter"
            >
              <div>
                <Icon className={clsx("size-4 stroke-[1.5]")} />
              </div>
            </Button>
          </div>

          <div
            className={clsx(
              "Filter__tabsGradientMask",
              "absolute w-20 right-0 top-0 h-8 z-10 pointer-events-none",
              "from-white/0 via-white from-0% via-30% to-white bg-gradient-to-r"
            )}
          />

          <Tabs
            defaultValue={selectedContext}
            onValueChange={onContextChange}
            value={selectedContext}
            className={clsx(
              "Filter__tabsRoot",
              "flex flex-col gap-y-4 overflow-hidden"
            )}
          >
            <div
              className={clsx(
                "Filter__tabs",
                "flex justify-between items-center overflow-hidden"
              )}
            >
              <ScrollArea className={clsx("Filter__tabsScrollArea", "w-full")}>
                <TabsList
                  className={clsx(
                    "Filter__tabsList",
                    "px-2 lg:px-4 pr-20 -space-x-2 lg:space-x-0 inline-flex w-max h-full"
                  )}
                >
                  {contexts.map((context) => (
                    <TabsTrigger
                      key={context.key}
                      value={context.key}
                      onClick={() => setIsFilterOpen(true)}
                      className={clsx("Filter__tabsTrigger", {
                        "!text-zinc-500": !isFilterOpen,
                      })}
                      asChild
                    >
                      <Button variant="ghost">{context.name}</Button>
                    </TabsTrigger>
                  ))}
                </TabsList>
                <ScrollBar
                  orientation="horizontal"
                  className={clsx("invisible")}
                />
              </ScrollArea>
            </div>

            {isFilterOpen && (
              <div
                className={clsx(
                  "Filter__tabsContent",
                  "px-6 lg:px-8 lg:pr-16 py-1 overflow-hidden"
                )}
              >
                {children}
              </div>
            )}

            {selectedItems.length > 0 && (
              <div className={clsx("Filter__selectedItems", "w-full")}>
                <ScrollArea className="w-full">
                  <div className={clsx("flex items-center")}>
                    <div className={clsx("flex gap-x-2 px-6 lg:px-8")}>
                      {selectedItems.map((item) => (
                        <Button
                          key={item.id}
                          variant="secondary"
                          size="sm"
                          asChild
                        >
                          {item.href ? (
                            <Link
                              href={item.href}
                              className={clsx("flex gap-x-1")}
                            >
                              {item.label}
                              <X className={clsx("size-3 stroke-[1.5]")} />
                            </Link>
                          ) : (
                            item.label
                          )}
                        </Button>
                      ))}
                    </div>
                  </div>
                  <ScrollBar
                    orientation="horizontal"
                    className={clsx("invisible")}
                  />
                </ScrollArea>
              </div>
            )}
          </Tabs>
        </div>
      </div>
    </FilterContext.Provider>
  );
};

// Utility components for common use cases
export const FilterTabContent = ({
  value,
  children,
}: {
  value: string;
  children: ReactNode;
}) => {
  return (
    <TabsContent value={value} className={clsx("max-w-6xl group/tab")}>
      {children}
    </TabsContent>
  );
};

export const FilterShowMoreButton = ({
  onClick,
  showingAll: _,
}: {
  onClick: () => void;
  showingAll: boolean;
}) => {
  return (
    <button
      className={clsx(
        linkClassName,
        "transition ease-out text-zinc-500 underline underline-offset-4 decoration-1 decoration-zinc-300 hover:text-zinc-950"
      )}
      onClick={onClick}
    >
      {/* ... existing code ... */}
    </button>
  );
};

export const FilterLink = ({
  href,
  isSelected,
  disabled,
  count,
  icon,
  children,
}: Omit<FilterLinkProps, "isFilterOpen">) => {
  return (
    <Link
      href={href}
      scroll={false}
      replace
      className={clsx(
        "Filter__link",
        linkClassName,
        "transition ease-out group relative",
        {
          "hover:text-zinc-500": !isSelected && !disabled,
          "text-zinc-500 selected": isSelected,
          "pointer-events-none text-zinc-200": disabled,
        }
      )}
      aria-disabled={disabled}
    >
      <div>
        <div className={clsx("flex space-x-2 items-center")}>
          <div
            className={clsx("text-current", {
              "text-red-500": isSelected,
            })}
          >
            {children}
          </div>
          {icon && (
            <div
              className={clsx(
                "group-hover:opacity-0 absolute -right-2 translate-x-full opacity-100 transition ease-out"
              )}
            >
              {icon}
            </div>
          )}
        </div>
      </div>
      {count !== undefined && (
        <div
          className={clsx(
            "absolute flex inset-y-0 pr-12 pl-2 z-10 right-0 group-hover:opacity-100 items-center w-auto translate-x-full bg-gradient-to-r via-white to-transparent from-white pointer-events-none opacity-0 transition ease-out"
          )}
        >
          <div
            className={clsx(
              "flex items-center justify-center text-sm lg:text-base lining-nums text-zinc-950 bg-zinc-100 rounded-full px-2"
            )}
          >
            {count.toLocaleString("en", { useGrouping: true })}
          </div>
        </div>
      )}
    </Link>
  );
};
