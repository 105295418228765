"use client";

import useWebsitesCollectionState from "@/app/(main)/websites/hooks/useWebsitesCollectionState";
import { Button, ButtonProps } from "@/app/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/app/components/ui/tooltip";
import { useAuthContext } from "@/contexts/AuthContext";
import { useCollectionContext } from "@/contexts/CollectionContext";
import { TooltipArrow } from "@radix-ui/react-tooltip";
import clsx from "clsx";
import { Bookmark, Loader2 } from "lucide-react";
import Link from "next/link";

type AddToCollectionButtonProps = {
  websiteId: number;
  variant?: ButtonProps["variant"];
  className?: string;
  size?: ButtonProps["size"];
};

const AddToCollectionButton = ({
  websiteId,
  variant = "secondary",
  className,
  size = "icon",
}: AddToCollectionButtonProps) => {
  const { status, user } = useAuthContext();
  const { setOpen, setWebsiteId } = useCollectionContext();
  const { isWebsiteSaved } = useWebsitesCollectionState();

  const isSaved = isWebsiteSaved(websiteId);

  const targetVariant = isSaved ? "default" : variant;

  const handleClick = () => {
    setWebsiteId(websiteId);
    setOpen(true);
  };

  if (status === "loading") {
    return (
      <TriggerButton
        isLoading={true}
        variant={targetVariant}
        className={className}
        size={size}
      />
    );
  }

  if (status !== "authenticated") {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link href="/signin" className="block">
              <TriggerButton
                isLoading={false}
                variant={targetVariant}
                className={className}
                size={size}
              />
            </Link>
          </TooltipTrigger>
          <TooltipContent side="top" sideOffset={2}>
            <TooltipArrow className="fill-zinc-900" />
            <Link
              href="/signin"
              className={clsx(
                "underline decoration-white/50 underline-offset-2"
              )}
            >
              Sign in
            </Link>{" "}
            or{" "}
            <Link
              href="/signup"
              className={clsx(
                "underline decoration-white/50 underline-offset-2"
              )}
            >
              sign up
            </Link>{" "}
            to add this website to a collection.
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return (
    <TriggerButton
      isLoading={false}
      variant={targetVariant}
      onClick={handleClick}
      className={className}
      size={size}
    />
  );
};

type TriggerButtonProps = {
  isLoading: boolean;
  variant?: ButtonProps["variant"];
  onClick?: () => void;
  className?: string;
  size?: ButtonProps["size"];
};

const TriggerButton = ({
  isLoading,
  variant = "secondary",
  onClick,
  className,
  size = "icon",
}: TriggerButtonProps) => {
  if (isLoading) {
    return (
      <Button variant="secondary" disabled className={className} size={size}>
        <Loader2 className={clsx("size-4 stroke-[1.5] animate-spin")} />
      </Button>
    );
  }

  return (
    <Button
      variant={variant}
      className={clsx("", className, {})}
      onClick={onClick}
      disabled={isLoading}
      size={size}
    >
      <Bookmark
        className={clsx("size-4 stroke-[1.5]", {
          "fill-white": variant === "default",
        })}
      />
    </Button>
  );
};

export default AddToCollectionButton;
