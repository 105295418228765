import AddToCollectionButton from "@/app/(main)/collections/components/AddToCollectionButton";
import WebsiteUrlButton from "@/app/main/websites/categories/WebsiteUrlButton";
import WebflowToken from "@/app/main/websites/components/WebflowToken";
import WebsiteCaption from "@/app/main/websites/components/WebsiteCaption";
import WebsiteThumbnail from "@/app/main/websites/components/WebsiteThumbnail";
import useWebsitesCollectionState from "@/hooks/useWebsitesCollectionState";
import clsx from "clsx";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import Link from "next/link";
import React from "react";

dayjs.extend(relative);

type WebsiteCardProps = {
  id: number;
  slug: string | null;
  title: string | null;
  url?: string | null;
  publishedAt: string | null;
  isSelected: boolean | null;
  credits?: Array<{
    id: number;
    creditExpertises?: Array<{
      id: number;
      expertise: {
        id: number;
        name: string | null;
        slug: string | null;
      } | null;
    }>;
  }>;
  versions?: Array<{
    imageData: unknown;
  }>;
};

type Props = {
  website: WebsiteCardProps;
  variant?: "default" | "horizontal";
};

const WebsiteCard = ({ website, variant = "default" }: Props) => {
  const { isWebsiteSaved } = useWebsitesCollectionState();

  return (
    <div className={clsx("WebsiteCard group @container", {})}>
      <div className={clsx("", {})}>
        <div
          className={clsx(
            "WebsiteCard__content",
            "relative flex gap-x-4 gap-y-1",
            {
              "flex-col": variant === "default",
              "flex-row": variant === "horizontal",
            }
          )}
        >
          <WebflowToken
            websiteId={website.id}
            showCaption={false}
            className={clsx(
              "absolute z-20",
              variant === "horizontal"
                ? "right-4 top-4 @xs:right-4 @xs:top-4"
                : "right-8 top-8 @xs:right-8 @xs:top-8"
            )}
          />

          <div
            className={clsx(
              "WebsiteCard__image",
              "overflow-hidden @container relative",
              {
                "w-full": variant === "default",
                "w-1/3": variant === "horizontal",
              }
            )}
          >
            <Link
              href={`/website/${website.id}-${website.slug}`}
              className={clsx(
                "WebsiteCard__imageWrapper",
                "block rounded bg-zinc-100 p-2 @2xs:p-6 @3xs:p-4"
              )}
              draggable={false}
              scroll={false}
            >
              <WebsiteThumbnail website={website} />
            </Link>

            <div
              className={clsx(
                "WebsiteCard__toolbar",
                "hidden absolute z-20 lg:opacity-0 transition-opacity  opacity-100 group-hover:opacity-100 bottom-10 right-10 @2xs:flex space-x-2"
              )}
            >
              <AddToCollectionButton websiteId={website.id} size="iconSm" />

              {website.title && website.url && (
                <WebsiteUrlButton
                  website={{ url: website.url, title: website.title }}
                  variant={"secondary"}
                  size={"iconSm"}
                />
              )}
            </div>
          </div>

          <div
            className={clsx("WebsiteCard__caption", {
              "flex-1": variant === "horizontal",
              "w-full": variant === "default",
              "w-2/3": variant === "horizontal",
            })}
          >
            <WebsiteCaption website={website} variant={variant} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(WebsiteCard);
