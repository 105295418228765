import type { WebsiteDetail } from "@/app/main/websites/services/fetchWebsite";
import React, { createContext, ReactNode, useContext, useState } from "react";

// Define the context type
type CollectionContextType = {
  open: boolean;
  setOpen: (value: boolean) => void;
  websiteId: number | null;
  setWebsiteId: (websiteId: number | null) => void;
};

// Create the context
const CollectionContext = createContext<CollectionContextType | undefined>(
  undefined
);

// Create a provider component
export const CollectionContextProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [websiteId, setWebsiteId] = useState<number | null>(null);

  const value = {
    open,
    setOpen,
    websiteId,
    setWebsiteId,
  };

  return (
    <CollectionContext.Provider value={value}>
      {children}
    </CollectionContext.Provider>
  );
};

// Create a custom hook to use the context
export const useCollectionContext = (): CollectionContextType => {
  const context = useContext(CollectionContext);
  if (context === undefined) {
    throw new Error(
      "useCollectionContext must be used within CollectionContextProvider"
    );
  }
  return context;
};
