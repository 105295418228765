import SelectedLink from "@/app/main/websites/components/SelectedLink";
import clsx from "clsx";
import dayjs from "dayjs";
import relative from "dayjs/plugin/relativeTime";
import Link from "next/link";

dayjs.extend(relative);

type Credit = {
  id: number;
  creditExpertises?: Array<{
    id: number;
    expertise: {
      id: number;
      name: string | null;
      slug: string | null;
    } | null;
  }>;
};

type Website = {
  id: number;
  slug: string | null;
  title: string | null;
  publishedAt: string | null;
  isSelected: boolean | null;
  credits?: Credit[];
};

const WebsiteCaption = ({
  website,
  variant = "default",
}: {
  website: Website;
  variant: "default" | "horizontal";
}) => {
  return (
    <div className={clsx("WebsiteCaption", "w-full flex flex-col space-y-1")}>
      <div className={clsx("flex items-start")}>
        <Link
          href={`/website/${website.id}-${website.slug}`}
          className={clsx(
            "WebsiteCaption__title",
            "flex flex-1 gap-x-2 transition-colors ease-out hover:text-zinc-500",
            {
              "flex-col gap-y-1": variant === "horizontal",
              "flex-row flex-wrap items-end pt-2": variant === "default",
            }
          )}
        >
          <span>{website.title}</span>
          <span className={clsx("hidden text-zinc-500 @xs:block")}>
            {dayjs(website.publishedAt).fromNow()}
          </span>
        </Link>
        <div
          className={clsx("WebsiteCaption__selected", "relative", {
            "top-3.5": variant === "default",
            "top-1.5": variant === "horizontal",
          })}
        >
          {website.isSelected && <SelectedLink />}
        </div>
      </div>
      {website.credits && website.credits.length > 0 && (
        <div className={clsx("WebsiteCaption__credits")}>
          <div className={clsx("flex flex-wrap text-zinc-400")}>
            {(website.credits as Credit[]).flatMap((credit) =>
              (credit.creditExpertises ?? []).map((creditExpertise) => (
                <div
                  key={`${credit.id}-${creditExpertise.id}`}
                  className={clsx("mr-2")}
                >
                  {creditExpertise.expertise?.name}
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WebsiteCaption;
