"use client";

import Slideshow from "@/app/components/Slideshow";
import type { FetchedWebsite } from "@/app/main/websites/services/fetchWebsites";
import clsx from "clsx";
import { useEffect, useState } from "react";

const DURATION = 0.5;

const HeroMobile = ({ websites }: { websites: FetchedWebsite[] }) => {
  const [showStandfirst, setShowStandfirst] = useState(true);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div className={clsx("pt-header relative block lg:hidden")}>
      <div className={clsx("mb:pt-6 px-6 py-6 pb-6 md:pb-4")}>
        <h2 className={clsx("text-center main-heading")}>
          A showcase of the web’s
          <br />
          finest design + talent
        </h2>
      </div>
      <div className={clsx("bg-zinc-100 py-6 lg:py-8")}>
        <Slideshow websites={websites} />
      </div>
    </div>
  );
};

export default HeroMobile;
