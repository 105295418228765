import(/* webpackMode: "eager" */ "/vercel/path0/client/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.1.6_@babel+core@7.24.5_@playwright+test@1.50.0_react-dom@1_tz374ptekyfsz4j4b3ptq2cmlm/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/client/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.1.6_@babel+core@7.24.5_@playwright+test@1.50.0_react-dom@1_tz374ptekyfsz4j4b3ptq2cmlm/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/client/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.1.6_@babel+core@7.24.5_@playwright+test@1.50.0_react-dom@1_tz374ptekyfsz4j4b3ptq2cmlm/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/client/node_modules/.pnpm/next@15.1.6_@babel+core@7.24.5_@playwright+test@1.50.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/client/node_modules/.pnpm/next@15.1.6_@babel+core@7.24.5_@playwright+test@1.50.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/local/target.css?{\"path\":\"src/lib/fontSctoGroteskA.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--font-scto\",\"src\":[{\"path\":\"../fonts/SctoGroteskA-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../fonts/SctoGroteskA-LightItalic.woff2\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"../fonts/SctoGroteskA-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../fonts/SctoGroteskA-RegularItalic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../fonts/SctoGroteskA-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../fonts/SctoGroteskA-MediumItalic.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../fonts/SctoGroteskA-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../fonts/SctoGroteskA-BoldItalic.woff2\",\"weight\":\"700\",\"style\":\"italic\"}]}],\"variableName\":\"fontSctoGroteskA\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/app/(main)/collections/components/CollectionDialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/client/src/app/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/contexts/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/providers/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/providers/ReactQueryProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/client/src/styles/globals.css");
