import searchProfiles from "@/app/main/account/profiles/actions/searchProfiles";
import searchWebsitesAction from "@/app/main/websites/actions/searchWebsitesAction";
import searchCategories from "@/lib/searchCategories";
import { useQuery } from "@tanstack/react-query";

const LIMIT = 24;

export default function useSearchResults(query: string) {
  const shouldSearch = !!query && query.length >= 3;

  const websitesQuery = useQuery({
    queryKey: ["searchWebsites", query],
    queryFn: () => searchWebsitesAction({ query, limit: LIMIT }),
    enabled: shouldSearch,
  });

  const categoriesQuery = useQuery({
    queryKey: ["searchCategories", query],
    queryFn: () => searchCategories({ query, limit: LIMIT }),
    enabled: shouldSearch,
  });

  const profilesQuery = useQuery({
    queryKey: ["searchProfiles", query],
    queryFn: () => searchProfiles({ query, limit: LIMIT, offset: 0 }),
    enabled: shouldSearch,
  });

  return {
    websites: websitesQuery.data?.websites || [],
    categories: categoriesQuery.data || [],
    profiles: profilesQuery.data || [],
    isWebsitesLoading: shouldSearch && websitesQuery.isFetching,
    isCategoriesLoading: shouldSearch && categoriesQuery.isFetching,
    isProfilesLoading: shouldSearch && profilesQuery.isFetching,
  };
}
