import { useAuthContext } from "@/contexts/AuthContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

type SavedWebsitesResponse = {
  websiteIds: number[];
};

export default function useWebsitesCollectionState() {
  const queryClient = useQueryClient();
  const { user, status } = useAuthContext();

  const { data: savedWebsiteIds = [] } = useQuery({
    queryKey: ["websitesCollectionsState", user?.id],
    queryFn: async () => {
      if (!user) return [] as number[];

      // Replace this with your actual API call
      const response = await fetch(`/api/users/${user.id}/saved-websites`);
      const data: SavedWebsitesResponse = await response.json();
      return data.websiteIds;
    },
    enabled: status === "authenticated",
  });

  const isWebsiteSaved = (websiteId: number) => {
    return savedWebsiteIds.includes(websiteId);
  };

  return {
    savedWebsiteIds,
    isWebsiteSaved,
  };
}
