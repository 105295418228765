"use client";

import fetchCollections from "@/app/main/collections/actions/fetchCollections";
import { useAuthContext } from "@/contexts/AuthContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const useCollections = ({ shouldFetch = false }: { shouldFetch: boolean }) => {
  // Get the current user
  const { user } = useAuthContext();
  const queryClient = useQueryClient();

  const {
    data,
    error,
    isLoading,
    refetch: originalRefetch,
  } = useQuery({
    queryKey: ["userCollections", user?.id],
    queryFn: () => fetchCollections({ userId: user!.id }),
    enabled: !!user && shouldFetch,
  });

  const refetch = async () => {
    // Refetch the collections query
    await originalRefetch();
    // Invalidate the count query for all websites
    await queryClient.invalidateQueries({
      queryKey: ["websiteUserCollectionsCount"],
    });

    queryClient.invalidateQueries({
      queryKey: ["websitesCollectionsState", user?.id],
    });
  };

  return {
    collections: data,
    error,
    refetch,
    isLoading,
  };
};

export default useCollections;
