"use client";

import { Button } from "@/app/components/ui/button";
import { Checkbox } from "@/app/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/app/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from "@/app/components/ui/drawer";
import { ScrollArea, ScrollBar } from "@/app/components/ui/scroll-area";
import type { FetchedCollection } from "@/app/main/collections/actions/fetchCollections";
import toggleCollection from "@/app/main/collections/actions/toggleCollection";
import CollectionForm from "@/app/main/collections/components/CollectionForm";
import DeleteCollectionButton from "@/app/main/collections/components/DeleteCollectionButton";
import useCollections from "@/app/main/collections/hooks/useCollections";
import { WebsiteDetail } from "@/app/main/websites/services/fetchWebsite";
import { useAuthContext } from "@/contexts/AuthContext";
import { useCollectionContext } from "@/contexts/CollectionContext";
import { useToast } from "@/hooks/use-toast";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { ArrowRight, Loader2 } from "lucide-react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "usehooks-ts";

const CollectionDialog = () => {
  const { status, user } = useAuthContext();
  const { open, setOpen, websiteId } = useCollectionContext();
  const collectionsContainerRef = useRef<HTMLDivElement>(null);
  const pathname = usePathname();

  const isDesktop = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    setOpen(false);
  }, [pathname, setOpen]);

  const {
    collections,
    refetch,
    isLoading: isLoadingCollections,
  } = useCollections({
    shouldFetch: open,
  });

  // Move the early return after all hooks are called
  if (!websiteId || status !== "authenticated") return null;

  const handleCreate = async () => {
    await refetch();
    if (collectionsContainerRef.current) {
      collectionsContainerRef.current.scrollTop =
        collectionsContainerRef.current.scrollHeight;
    }
  };

  const title = "Add to a collection";
  const description =
    "Here are all your collections. You can add this website to one or more.";

  const Content = () => {
    return (
      <div
        ref={collectionsContainerRef}
        className={clsx("h-full min-h-32 flex-1 overflow-y-auto ")}
      >
        {isLoadingCollections && (
          <div className={clsx("flex items-center justify-center p-12")}>
            <Loader2 className={clsx("size-4 stroke-[1.5] animate-spin")} />{" "}
          </div>
        )}

        {collections && collections.length > 0 && (
          <CollectionList
            collections={collections}
            websiteId={websiteId}
            refetch={refetch}
          />
        )}

        {collections && collections.length === 0 && (
          <div
            className={clsx("flex items-center justify-center p-6 text-center")}
          >
            You don't have any collections yet. Create one to get started.
          </div>
        )}
      </div>
    );
  };

  if (isDesktop) {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()}>
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            <VisuallyHidden>
              <DialogDescription>{description}</DialogDescription>
            </VisuallyHidden>
          </DialogHeader>

          <Content />

          <DialogFooter>
            <CollectionForm websiteId={websiteId} onCreate={handleCreate} />
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={open} onOpenChange={setOpen} repositionInputs={false}>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>{title}</DrawerTitle>
          <VisuallyHidden>
            <DrawerDescription>{description}</DrawerDescription>
          </VisuallyHidden>
        </DrawerHeader>

        <Content />

        <DrawerFooter>
          <CollectionForm websiteId={websiteId} onCreate={handleCreate} />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

const CollectionList = ({
  collections,
  websiteId,
  refetch,
}: {
  collections: FetchedCollection[];
  websiteId: number;
  refetch: () => void;
}) => {
  const [parent] = useAutoAnimate();

  return (
    <ScrollArea className={clsx("h-[50dvh]")}>
      <div ref={parent} className={clsx("py-3")}>
        {collections?.map((collection) => {
          const defaultChecked = collection.collectionWebsites.some(
            (collectionWebsite) =>
              collectionWebsite.websiteId !== null &&
              collectionWebsite.websiteId === websiteId
          );

          return (
            <CollectionRow
              key={collection.id}
              websiteId={websiteId}
              collection={collection}
              defaultChecked={defaultChecked}
              refetch={refetch}
            />
          );
        })}
      </div>
      <ScrollBar orientation="vertical" />
    </ScrollArea>
  );
};

const CollectionRow = ({
  collection,
  websiteId,
  defaultChecked,
  refetch,
}: {
  collection: FetchedCollection;
  websiteId: number;
  defaultChecked: boolean;
  refetch: () => void;
}) => {
  const { user } = useAuthContext();
  const [checked, setChecked] = useState(defaultChecked);
  const { toast } = useToast();

  const toggleMutation = useMutation({
    mutationFn: (value: boolean) => {
      const action = value ? "add" : "remove";
      return toggleCollection({
        collectionId: collection.id,
        websiteId,
        action,
      });
    },
    onSuccess: (_, value) => {
      const action = value ? "add" : "remove";
      toast({
        title:
          action === "add"
            ? `Website has been added to ${collection.title}`
            : `Website has been removed from ${collection.title}`,
      });
      refetch();
    },
  });

  const handleChange = (value: boolean) => {
    setChecked(value);
    toggleMutation.mutate(value);
  };

  return (
    <div
      className={clsx(
        "group flex cursor-pointer items-center px-6 h-12 lg:h-14 hover:border-zinc-300 hover:bg-zinc-50"
      )}
    >
      <Link
        href={`/collections/${collection.uuid}`}
        className={clsx("flex-1 flex items-center space-x-1 lg:space-x-2")}
      >
        <span>{collection.title}</span>
        <ArrowRight className="size-3.5 stroke-[1.5] group-hover:opacity-100 opacity-100 lg:opacity-0 transition lg:-translate-x-2 group-hover:translate-x-0 ease-out" />
      </Link>
      <div className={clsx("ml-auto flex items-center space-x-4")}>
        <div className={clsx("hidden group-hover:flex items-center space-x-1")}>
          <DeleteCollectionButton
            collectionId={collection.id}
            onDelete={() => {
              refetch();
              return null;
            }}
            trigger={
              <Button variant="destructiveGhost" size="xs">
                Delete
              </Button>
            }
          />
        </div>
        <Checkbox checked={checked} onCheckedChange={handleChange} />
      </div>
    </div>
  );
};

export default CollectionDialog;
