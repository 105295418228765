"use client";

import subscribe from "@/app/(main)/subscribers/actions/subscribe";
import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { ArrowRight } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

// Define form schema
const formSchema = z.object({
  email: z.string().email("Please enter a valid email address"),
});

type FormValues = z.infer<typeof formSchema>;

const NewsletterForm = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [serverError, setServerError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    reset,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (data: FormValues) => {
    setServerError(null);
    try {
      const response = await subscribe({ email: data.email });
      if (response.success) {
        setIsSuccess(true);
        reset();
      } else {
        // Handle server-side validation errors
        if (response.errors?.email) {
          setError("email", {
            type: "server",
            message: response.errors.email[0],
          });
        } else if (response.message && typeof response.message === "string") {
          setServerError(response.message);
        }
      }
    } catch (error) {
      console.error("Newsletter subscription failed:", error);
      setServerError("An unexpected error occurred. Please try again later.");
    }
  };

  if (isSuccess) {
    return (
      <div className="text-sm">
        Thank you for subscribing to our newsletter!
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={clsx("flex flex-1")}>
      <div className={clsx("flex-1")}>
        <input
          type="email"
          placeholder="Your email address"
          {...register("email")}
          className={clsx(
            "m-0 w-full p-0 text-sm focus:outline-none focus:ring-0",
            {
              "border-red-500": errors.email,
            }
          )}
        />
        {errors.email && (
          <p className="mt-1 text-xs text-red-500">{errors.email.message}</p>
        )}
        {serverError && (
          <p className="mt-1 text-xs text-red-500">{serverError}</p>
        )}
      </div>
      <div>
        <button
          type="submit"
          disabled={isSubmitting}
          className={clsx("transition-opacity", {
            "opacity-50": isSubmitting,
          })}
        >
          <ArrowRight className={clsx("size-4 stroke-[1.5]")} />
        </button>
      </div>
    </form>
  );
};

export default NewsletterForm;
