"use client";

import ProfileListItem from "@/app/(main)/profiles/components/ProfileListItem";
import useSearchResults from "@/app/(main)/search/hooks/useSearchResults";
import { ScrollArea, ScrollBar } from "@/app/components/ui/scroll-area";
import WebsiteCard from "@/app/main/websites/components/WebsiteCard";
import clsx from "clsx";
import { Loader2 } from "lucide-react";
import Link from "next/link";

const SearchResultsDesktop = ({ query }: { query: string }) => {
  return (
    <div className={clsx("SearchResultsDesktop")}>
      <div className={clsx("flex relative")}>
        <div className={clsx("flex-1")}>
          <ScrollArea className="h-[calc(100dvh-14rem)]">
            <Websites_ query={query} />
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        </div>

        <div className={clsx("w-80 shrink-0 flex border-l")}>
          <ScrollArea className="h-[calc(100dvh-14rem)] w-full">
            <div className={clsx("h-full")}>
              <div className={clsx("border-b py-8")}>
                <Categories_ query={query} />
              </div>
              <div className={clsx("py-8")}>
                <Profiles_ query={query} />
              </div>
            </div>
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        </div>
      </div>
    </div>
  );
};

const Header = ({ title, count }: { title: string; count: number }) => {
  return (
    <div className={clsx("text-lg flex gap-1.5 px-8 mb-4")}>
      <span>{title}</span>
      <span className={clsx("text-zinc-400")}>{count}</span>
    </div>
  );
};

const Websites_ = ({ query }: { query: string }) => {
  const { websites, isWebsitesLoading } = useSearchResults(query);

  return (
    <div className={clsx("border-b lg:border-b-0 py-8 px-8")}>
      {websites.length > 0 && (
        <div className={clsx("")}>
          <div className={clsx("grid grid-cols-2 lg:grid-cols-3 gap-8")}>
            {websites.map((website) => {
              return <WebsiteCard key={website.id} website={website} />;
            })}
          </div>
        </div>
      )}
      {!isWebsitesLoading && websites.length === 0 && (
        <div className={clsx("text-zinc-500")}>
          No websites.{" "}
          <Link
            href="/websites"
            className={clsx("text-zinc-700 underline underline-offset-2")}
          >
            See all.
          </Link>
        </div>
      )}
      {isWebsitesLoading && (
        <div className={clsx("px-8 py-8 text-zinc-500")}>
          <Loader2 className={clsx("size-4 stroke-[1.5] animate-spin")} />
        </div>
      )}
    </div>
  );
};

const Categories_ = ({ query }: { query: string }) => {
  const { categories, isCategoriesLoading } = useSearchResults(query);

  return (
    <div className={clsx("flex flex-col")}>
      <Header title="Categories" count={categories.length} />
      {categories.length > 0 && (
        <div className={clsx("flex flex-col")}>
          {categories.map((category) => {
            return (
              <Link
                key={category.id}
                href={`/websites/category/${category.slug}`}
                className={clsx("px-8 py-2")}
              >
                {category.name}
              </Link>
            );
          })}
        </div>
      )}
      {!isCategoriesLoading && categories.length === 0 && (
        <div className={clsx("text-zinc-500 px-8")}>
          No categories.{" "}
          <Link
            href="/websites"
            className={clsx("text-zinc-700 underline underline-offset-2")}
          >
            See all.
          </Link>
        </div>
      )}

      {isCategoriesLoading && (
        <div className={clsx("text-zinc-500 px-8")}>
          <Loader2 className={clsx("size-4 stroke-[1.5] animate-spin")} />
        </div>
      )}
    </div>
  );
};

const Profiles_ = ({ query }: { query: string }) => {
  const { profiles, isProfilesLoading } = useSearchResults(query);

  return (
    <div className={clsx("")}>
      <Header title="Profiles" count={profiles.length} />
      {profiles.length > 0 && (
        <div className={clsx("flex flex-col")}>
          {profiles.map((profile) => {
            return (
              <div key={profile.id} className={clsx("")}>
                <ProfileListItem profile={profile} />
              </div>
            );
          })}
        </div>
      )}
      {profiles.length === 0 && (
        <div className={clsx("text-zinc-500 px-8")}>
          No profiles.{" "}
          <Link
            href="/profiles"
            className={clsx("text-zinc-700 underline underline-offset-2")}
          >
            See all.
          </Link>
        </div>
      )}
      {isProfilesLoading && (
        <div className={clsx("text-zinc-500 px-8")}>
          <Loader2 className={clsx("size-4 stroke-[1.5] animate-spin")} />{" "}
        </div>
      )}
    </div>
  );
};

export default SearchResultsDesktop;
