"use client";

import SearchModal from "@/app/(main)/search/components/SearchModal";
import { Button } from "@/app/components/ui/button";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuViewport,
} from "@/app/components/ui/navigation-menu";
import { primaryItems } from "@/lib/navigation";
import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";

const PrimaryNavigation = ({ className }: { className?: string }) => {
  const pathname = usePathname();

  return (
    <NavigationMenu className={className}>
      <NavigationMenuList>
        {primaryItems.map((item) => {
          const isActive = pathname.startsWith(item.href);
          return (
            <NavigationMenuItem key={item.key}>
              <NavigationMenuLink active={isActive} asChild>
                <Button
                  variant="ghost"
                  asChild
                  className={clsx({
                    "text-zinc-500": !isActive,
                    "text-zinc-950": isActive || pathname === "/",
                  })}
                >
                  <Link href={item.href}>{item.label}</Link>
                </Button>
              </NavigationMenuLink>
            </NavigationMenuItem>
          );
        })}
        <NavigationMenuItem>
          <SearchModal />
        </NavigationMenuItem>
      </NavigationMenuList>
      <NavigationMenuViewport />
    </NavigationMenu>
  );
};

export default PrimaryNavigation;
