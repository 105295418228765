"use client";

import { getCurrentUser } from "@/app/(auth)/lib/getCurrentUser";
import { users } from "@/db/schema";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSearchParams } from "next/navigation";
import {
  createContext,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";

// Infer the type of users
type UserType = typeof users.$inferSelect;

type AuthUserType = Pick<
  UserType,
  "id" | "uuid" | "email" | "firstName" | "lastName" | "isAdmin"
> | null;

type AuthStatus = "loading" | "authenticated" | "unauthenticated";

const AuthContext = createContext<
  | {
      user: AuthUserType;
      setUser: React.Dispatch<React.SetStateAction<AuthUserType>>;
      status: AuthStatus;
      setStatus: React.Dispatch<React.SetStateAction<AuthStatus>>;
      isLoading: boolean;
      error: any;
      reset: () => void;
      refresh: () => Promise<void>;
    }
  | undefined
>(undefined);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context)
    throw new Error("useAuthContext must be used within a AuthContextProvider");
  return context;
};

type AuthContextInnerProps = {
  children: React.ReactNode;
};

const AuthContextInner: React.FC<AuthContextInnerProps> = ({ children }) => {
  const [user, setUser] = useState<AuthUserType>(null);
  const [status, setStatus] = useState<AuthStatus>("loading");
  const queryClient = useQueryClient();

  const searchParams = useSearchParams();

  const { data, error, isLoading } = useQuery({
    queryKey: ["currentUser"],
    queryFn: getCurrentUser,
    // Disable automatic refetching
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
  });

  const isSigninSuccess = searchParams.get("signin") === "success";

  useEffect(() => {
    if (isSigninSuccess) {
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
    }
  }, [isSigninSuccess, queryClient]);

  const reset = () => {
    setStatus("unauthenticated");
    setUser(null);
    queryClient.setQueryData(["currentUser"], null);
  };

  useEffect(() => {
    if (isLoading) {
      setStatus("loading");
      return;
    }

    if (error) {
      console.error("Failed to fetch user:", error);
      reset();
      return;
    }

    if (!data || !data.user) {
      setUser(null);
      setStatus("unauthenticated");
      return;
    }

    setUser(data.user);
    setStatus("authenticated");
  }, [data, isLoading, error]);

  const refresh = async () => {
    await queryClient.invalidateQueries({ queryKey: ["currentUser"] });
  };

  // Providing refreshAuthState in the context
  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        status,
        setStatus,
        isLoading,
        error,
        reset,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Suspense>
      <AuthContextInner>{children}</AuthContextInner>
    </Suspense>
  );
};
