import React, { createContext, useContext, useEffect, useState } from "react";

// Define the context type
type SearchContextType = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  toggleSearch: () => void;
};

// Create the context
const SearchContext = createContext<SearchContextType | undefined>(undefined);

// Create a provider component
export const SearchContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleSearch = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      const modifier = isMac ? event.metaKey : event.ctrlKey;

      if (modifier && event.key === "k") {
        event.preventDefault();
        toggleSearch();
      }

      // Also close on escape key
      if (event.key === "Escape" && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isOpen]);

  const value = { isOpen, setIsOpen, toggleSearch };

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
};

// Create a custom hook to use the context
export const useSearchContext = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error(
      "useSearchContext must be used within a SearchContextProvider"
    );
  }
  return context;
};
