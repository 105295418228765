"use client";

import ProfileListItem from "@/app/(main)/profiles/components/ProfileListItem";
import useSearchResults from "@/app/(main)/search/hooks/useSearchResults";
import { Badge } from "@/app/components/ui/badge";
import { Button } from "@/app/components/ui/button";
import { ScrollArea, ScrollBar } from "@/app/components/ui/scroll-area";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/app/components/ui/tabs";
import WebsiteCard from "@/app/main/websites/components/WebsiteCard";
import clsx from "clsx";
import { Loader2 } from "lucide-react";
import Link from "next/link";

const Tab = ({
  value,
  label,
  count,
}: {
  value: string;
  label: string;
  count: number;
}) => {
  return (
    <TabsTrigger value={value} asChild>
      <Button className={clsx("flex gap-x-2")} variant="ghost" size="sm">
        {label} <Badge variant="secondary">{count}</Badge>
      </Button>
    </TabsTrigger>
  );
};

const SearchResultsMobile = ({ query }: { query: string }) => {
  const {
    websites,
    categories,
    profiles,
    isWebsitesLoading,
    isCategoriesLoading,
    isProfilesLoading,
  } = useSearchResults(query);

  return (
    <div className={clsx("h-[calc(100dvh-7rem)]")}>
      <Tabs defaultValue="websites" className={clsx("h-full flex flex-col")}>
        <TabsList
          className={clsx("px-2 h-header shrink-0 flex items-center border-b")}
        >
          <Tab count={websites.length} label="Websites" value="websites" />
          <Tab count={profiles.length} label="Profiles" value="profiles" />
          <Tab
            count={categories.length}
            label="Categories"
            value="categories"
          />
        </TabsList>

        <TabsContent value="websites" className={clsx("px-6")}>
          <ScrollArea className={clsx("h-[calc(100dvh-4rem*3)]")}>
            {isWebsitesLoading ? (
              <LoadingState />
            ) : websites.length > 0 ? (
              <div className={clsx("flex flex-col gap-y-4 py-6")}>
                {websites.map((website) => (
                  <WebsiteCard
                    key={website.id}
                    website={website}
                    variant="horizontal"
                  />
                ))}
              </div>
            ) : (
              <EmptyState href="/websites" type="websites" />
            )}
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        </TabsContent>

        <TabsContent value="categories" className={clsx("")}>
          <ScrollArea className={clsx("h-[calc(100dvh-4rem*3)]")}>
            {isCategoriesLoading ? (
              <LoadingState />
            ) : categories.length > 0 ? (
              <div className={clsx("flex flex-col divide-y")}>
                {categories.map((category) => (
                  <Link
                    key={category.id}
                    href={`/websites/category/${category.slug}`}
                    className={clsx("px-6 py-4")}
                  >
                    {category.name}
                  </Link>
                ))}
              </div>
            ) : (
              <EmptyState href="/websites" type="categories" />
            )}
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        </TabsContent>

        <TabsContent value="profiles" className={clsx("")}>
          <ScrollArea className={clsx("h-[calc(100dvh-4rem*3)]")}>
            {isProfilesLoading ? (
              <LoadingState />
            ) : profiles.length > 0 ? (
              <div className={clsx("flex flex-col divide-y")}>
                {profiles.map((profile) => (
                  <ProfileListItem key={profile.id} profile={profile} />
                ))}
              </div>
            ) : (
              <EmptyState href="/profiles" type="profiles" />
            )}
            <ScrollBar orientation="vertical" />
          </ScrollArea>
        </TabsContent>
      </Tabs>
    </div>
  );
};

const LoadingState = () => (
  <div className={clsx("flex justify-center text-zinc-500 p-6")}>
    <Loader2 className={clsx("size-4 stroke-[1.5] animate-spin")} />
  </div>
);

const EmptyState = ({ href, type }: { href: string; type: string }) => (
  <div className={clsx("text-zinc-500 p-6")}>
    No {type}.{" "}
    <Link
      href={href}
      className={clsx("text-zinc-700 underline underline-offset-2")}
    >
      See all.
    </Link>
  </div>
);

export default SearchResultsMobile;
