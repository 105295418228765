"use client";

import SearchToggle from "@/app/(main)/search/components/SearchToggle";
import Logotype from "@/app/components/Logotype";
import { useHeaderContext } from "@/contexts/HeaderContext";
import clsx from "clsx";
import Link from "next/link";
import { usePathname } from "next/navigation";

const HeaderMobile = () => {
  const { transparent } = useHeaderContext();
  const pathname = usePathname();

  return (
    <header
      className={clsx(
        "HeaderMobile",
        "fixed inset-x-0 top-0 z-50 w-screen max-w-full px-6 transition ease-out lg:hidden",
        {
          "bg-white": !transparent,
          "bg-transparent": transparent,
          // "border-b": pathname !== "/",
        }
      )}
    >
      <div
        className={clsx("HeaderMobile__content", "h-header flex items-center")}
      >
        <div className={clsx("HeaderMobile__logotypeContainer", "")}>
          <Link
            href="/"
            className={clsx(
              "HeaderMobile__logotype",
              "flex items-center space-x-4"
            )}
          >
            <div>
              <Logotype theme={transparent ? "light" : "dark"} />
            </div>
            <div className={clsx("hidden")}>Siteinspire</div>
          </Link>
        </div>
        <div className={clsx("absolute right-16")}>
          <SearchToggle />
        </div>
      </div>
    </header>
  );
};

export default HeaderMobile;
