"use client";

import SearchResultsDesktop from "@/app/(main)/search/components/SearchResultsDesktop";
import SearchResultsMobile from "@/app/(main)/search/components/SearchResultsMobile";
import { Button } from "@/app/components/ui/button";
import { Input } from "@/app/components/ui/input";
import { useSearchContext } from "@/contexts/SearchContext";
import { easeInOutQuad } from "@/lib/easing";
import * as Dialog from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import clsx from "clsx";
import { Search, X } from "lucide-react";
import { AnimatePresence, motion } from "motion/react";
import { usePathname, useSearchParams } from "next/navigation";
import { useCallback, useEffect, useState } from "react";
import { usePreventScroll } from "react-aria";
import { useDebounce } from "use-debounce";
import { useMediaQuery } from "usehooks-ts";

const limit = 24;

const SearchModal = () => {
  const { isOpen, setIsOpen } = useSearchContext();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 300); // 300ms delay

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  // When the pathname or search params change, close the modal
  useEffect(() => {
    if (isMounted && isOpen) {
      setIsOpen(false);
    }
  }, [searchParams, pathname]);

  // Determine the icon to display based on whether the modal is open
  const Icon = isOpen ? X : Search;

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  // Prevent scrolling when the modal is open
  usePreventScroll({ isDisabled: !isOpen });

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isOpen, setIsOpen]);

  const handleOverlayClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const isDesktop = useMediaQuery("(min-width: 1024px)");

  return (
    <Dialog.Root open={isOpen} modal={false}>
      <Dialog.Trigger asChild>
        <Button
          className={clsx("SearchModal__trigger", "flex gap-2")}
          onClick={handleClick}
          variant="ghost"
        >
          <div>Search</div>
          <Icon className={clsx("size-4 stroke-[1.5]")} aria-hidden />
        </Button>
      </Dialog.Trigger>
      <AnimatePresence>
        {isOpen && (
          <Dialog.Portal forceMount>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: easeInOutQuad, duration: 0.5 }}
              className={clsx(
                "fixed inset-0 z-40 block bg-black bg-opacity-50 "
              )}
            />
            <Dialog.Content key="content" asChild>
              <motion.div
                initial={{ y: isDesktop ? "-7rem" : "-4rem" }}
                animate={{ y: "0rem" }}
                exit={{ y: isDesktop ? "-7rem" : "-4rem" }}
                transition={{ ease: "easeOut", duration: 0.25 }}
                className={clsx(
                  "z-40 w-screen max-w-full fixed overflow-hidden  inset-x-0 bg-white top-header flex flex-col",
                  {
                    "lg:h-[calc(100dvh-7rem)]": !!query && query.length >= 3,
                  }
                )}
                onClick={(e) => e.stopPropagation()}
              >
                <VisuallyHidden.Root>
                  <Dialog.Title>Search</Dialog.Title>
                </VisuallyHidden.Root>
                <div
                  className={clsx(
                    "SearchModal__query",
                    "px-6 lg:px-8 border-b"
                  )}
                >
                  <div className="relative h-header flex items-center">
                    <div className={clsx("flex-1")}>
                      <Input
                        type="text"
                        className={clsx(
                          "text-base lg:text-xl border-0 w-full flex p-0 m-0 focus:outline-none focus:ring-0"
                        )}
                        placeholder="Search..."
                        autoFocus
                        value={query}
                        onChange={(e) => {
                          setQuery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {!!debouncedQuery && debouncedQuery.length >= 3 && (
                  <>
                    {isDesktop && (
                      <SearchResultsDesktop query={debouncedQuery} />
                    )}
                    {!isDesktop && (
                      <SearchResultsMobile query={debouncedQuery} />
                    )}
                  </>
                )}
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  );
};

export default SearchModal;
