import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/app/components/ui/tooltip";
import clsx from "clsx";
import Link from "next/link";

const SelectedLink = () => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Link
          href={{
            pathname: "/websites/selected",
          }}
          passHref
          aria-label="Siteinspire Selected"
          className={clsx("block h-2 w-2 rounded-full bg-red-500")}
        />
      </TooltipTrigger>
      <TooltipContent>
        <div>Siteinspire Selected: one of our favourite websites</div>
        <Link
          href={{
            pathname: "/websites/selected",
          }}
          className={clsx(
            "underline decoration-zinc-500 underline-offset-2 transition ease-out hover:opacity-75"
          )}
        />
      </TooltipContent>
    </Tooltip>
  );
};

export default SelectedLink;
