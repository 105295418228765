import fetchWebsitesCollectionState from "@/app/(main)/websites/actions/fetchWebsitesCollectionState";
import { useAuthContext } from "@/contexts/AuthContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export default function useWebsitesCollectionState() {
  const queryClient = useQueryClient();
  const { user, status } = useAuthContext();

  const { data: savedWebsiteIds = [] } = useQuery({
    queryKey: ["websitesCollectionsState", user?.id],
    queryFn: async () => {
      if (!user) return [];

      return fetchWebsitesCollectionState({ userId: user.id });
    },
    enabled: status === "authenticated",
  });

  const isWebsiteSaved = (websiteId: number) => {
    return savedWebsiteIds.includes(websiteId);
  };

  const updateSavedWebsite = (websiteId: number, isSaving: boolean) => {
    queryClient.setQueryData(
      ["websitesCollectionsState", user?.id],
      (old: number[] = []) => {
        if (isSaving && !old.includes(websiteId)) {
          return [...old, websiteId];
        }
        return old.filter((id) => id !== websiteId);
      }
    );
  };

  return {
    savedWebsiteIds,
    isWebsiteSaved,
    updateSavedWebsite,
  };
}
