import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";

type Website = {
  title: string | null;
  url: string | null;
  versions?: Array<{
    imageData?: {
      id: string;
    };
  }>;
};

type ScreengrabProps = {
  website: Website;
  inverse?: boolean;
  href?: string | null;
  priority?: boolean;
  slideshowContext?: boolean;
};

const Screengrab = ({
  website,
  inverse = false,
  href = null,
  priority = false,
  slideshowContext = false,
}: ScreengrabProps) => {
  if (!website) {
    return null;
  }

  // The image will be displayed 100vw until 1024, then 2/3 of the viewport
  const sizes = "(min-width: 1024px) 66.666vw, 100vw";

  // Determine the image src
  const imageSrc = !!website.versions?.[0]?.imageData
    ? `/${website.versions[0].imageData?.id}`
    : null;

  return (
    <Wrapper_
      className={clsx("Screengrab", "block w-full overflow-hidden rounded", {
        "bg-zinc-100": !inverse,
        "bg-white": inverse,
        "p-6": slideshowContext,
        "p-6 lg:p-6": !slideshowContext,
      })}
      href={href}
    >
      <div
        className={clsx("relative aspect-[16/10] overflow-hidden rounded-sm")}
      >
        {!!imageSrc && (
          <Image
            src={imageSrc}
            alt={website.title || "Screengrab"}
            fill
            className={clsx("object-cover object-top")}
            sizes={sizes}
            priority={priority}
            draggable={false}
          />
        )}
      </div>
    </Wrapper_>
  );
};

type WrapperProps = {
  children: React.ReactElement;
  className: string;
  href?: string | null;
};

const Wrapper_ = ({ children, className, href }: WrapperProps) => {
  if (!!href) {
    return (
      <Link href={href} target="_blank" rel="noreferrer" className={className}>
        {children}
      </Link>
    );
  }

  return <div className={className}>{children}</div>;
};

export default Screengrab;
