import webflow from "@/images/webflow.jpg";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";

const Ghost = () => {
  return (
    <Link
      href={
        "https://webflow.com/?utm_source=siteinspire&utm_medium=referral&utm_campaign=partnerships"
      }
      target="_blank"
      rel="noreferrer"
    >
      <div
        className={clsx(
          "Ghost__image",
          "overflow-hidden aspect-w-16 aspect-h-11"
        )}
      >
        <div className={clsx("Ghost__imageWrapper", "bg-[#166ef7]/10 p-6")}>
          <div className={clsx("relative", "aspect-[16/10] overflow-hidden")}>
            <Image
              src={webflow}
              fill
              className={clsx(
                "WebsiteCard__image",
                "block w-full object-cover"
              )}
              alt={"Supported by Webflow"}
              unoptimized
            />
          </div>
        </div>
      </div>
      <div className={clsx("Ghost__title", "pt-2")}>Supported by Webflow</div>
    </Link>
  );
};

export default Ghost;
