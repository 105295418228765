"use client";

import signout from "@/app/(auth)/actions/signout";
import { Button } from "@/app/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/app/components/ui/dropdown-menu";
import { useAuthContext } from "@/contexts/AuthContext";
import userNavItems from "@/lib/userNavItems";
import clsx from "clsx";
import { ChevronDown, Loader2, UserRoundIcon } from "lucide-react";
import Link from "next/link";
import { useState } from "react";

type UserMenuProps = {
  signOut: () => Promise<void>;
};

const baseClassName = clsx(
  "bg-zinc-100 h-8 px-3 flex items-center justify-center rounded-full group transition ease-out"
);

const UserMenu = () => {
  const { user, status, reset } = useAuthContext();
  const [open, setOpen] = useState(false);

  if (status === "loading")
    return (
      <Button variant="secondary" size="icon" disabled>
        <Loader2 className={clsx("size-4 stroke-[1.5] animate-spin")} />{" "}
      </Button>
    );

  if (status === "unauthenticated") {
    return (
      <Button asChild>
        <Link href="/signin" className={clsx("SiteHeader__authLink")}>
          <UserRoundIcon className="w-4 h-4 mr-1.5 stroke-[1.5]" />
          <div>Sign In</div>
        </Link>
      </Button>
    );
  }

  if (status === "authenticated" && !!user) {
    return (
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger className={clsx("UserMenu__button")} asChild>
          <Button variant="secondary" className="space-x-2">
            <div className="whitespace-nowrap">Hi {user.firstName}</div>
            <div className="data-[state=open]:rotate-180">
              <ChevronDown className={clsx("size-4 stroke-[1.5]")} />
            </div>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          className="w-48"
          side="top"
          sideOffset={5}
          collisionPadding={24}
        >
          <DropdownMenuGroup>
            {userNavItems.map((item) => (
              <DropdownMenuItem key={item.key} asChild>
                <Link href={item.href}>{item.label}</Link>
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              setOpen(false);
              Promise.all([signout(), reset()]).catch((error) => {
                console.error("Error during sign out:", error);
              });
            }}
          >
            Sign Out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  return <div>{status}</div>;
};

export default UserMenu;
