"use client";
import Logo from "@/app/components/Logo";
import clsx from "clsx";
import { motion } from "motion/react";
import { usePathname } from "next/navigation";
import { useEffect, useRef, useState } from "react";

const Logotype = ({
  theme = "dark",
  showLogotype = true,
  animateOut = false,
}) => {
  const [show, setShow] = useState(showLogotype);
  const [isHovering, setIsHovering] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [hasTimerElapsed, setHasTimerElapsed] = useState(false);
  const prevPathname = useRef<string | undefined>(undefined);
  const isInitialMount = useRef(true);

  // Hide logo after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setHasTimerElapsed(true);
      setShow(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  // Hide logo on first scroll
  useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolled) {
        setHasScrolled(true);
        setShow(false);
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasScrolled]);

  // Update show state based on hover only after initial timer/scroll
  useEffect(() => {
    if (hasTimerElapsed || hasScrolled) {
      setShow(isHovering);
    }
  }, [isHovering, hasTimerElapsed, hasScrolled]);

  // When the pathname changes, hide
  const pathname = usePathname();

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (prevPathname.current && prevPathname.current !== pathname) {
      setShow(false);
    }
    prevPathname.current = pathname;
  }, [pathname]);

  return (
    <div
      className={clsx("Logotype", "flex items-center space-x-4")}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Logo theme={theme} />

      <motion.div
        key="logotype"
        className={clsx(
          "Logotype__type",
          "relative top-0.5 flex h-4.5 lg:h-5 text-base  justify-start font-medium leading-none",
          { "text-black": theme === "dark", "text-white": theme === "light" }
        )}
        initial={
          isInitialMount.current ? { opacity: 1, y: 0 } : { opacity: 0, y: 2 }
        }
        animate={{ opacity: show ? 1 : 0, y: show ? 0 : 2 }}
        exit={{ opacity: 0, y: -4 }}
        transition={{ duration: 0.2, ease: "easeOut" }}
      >
        <svg
          viewBox="0 0 280 58"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          className={clsx("block h-full")}
        >
          <path
            d="M16.603 45.608C13.428 45.608 10.589 45.042 8.082 43.915C5.577 42.788 3.603 41.085 2.161 38.81C0.721 36.533 0 33.682 0 30.259V28.941H5.201V30.259C5.201 33.891 6.266 36.596 8.395 38.371C10.525 40.146 13.26 41.034 16.603 41.034C20.026 41.034 22.637 40.261 24.433 38.717C26.228 37.172 27.128 35.23 27.128 32.888C27.128 31.259 26.721 29.965 25.906 29.005C25.091 28.045 23.953 27.26 22.492 26.654C21.029 26.052 19.317 25.518 17.354 25.058L14.096 24.243C11.549 23.577 9.294 22.762 7.331 21.802C5.366 20.841 3.832 19.578 2.726 18.012C1.619 16.446 1.066 14.431 1.066 11.966C1.066 9.5 1.681 7.37 2.914 5.574C4.145 3.781 5.878 2.403 8.114 1.439C10.348 0.479 12.927 0 15.85 0C18.816 0 21.478 0.512 23.838 1.533C26.198 2.557 28.056 4.063 29.413 6.044C30.771 8.028 31.451 10.524 31.451 13.532V16.164H26.249V13.532C26.249 11.402 25.801 9.691 24.903 8.394C24.005 7.101 22.773 6.138 21.207 5.514C19.641 4.887 17.856 4.571 15.85 4.571C12.969 4.571 10.651 5.211 8.897 6.483C7.143 7.758 6.266 9.564 6.266 11.902C6.266 13.447 6.641 14.701 7.393 15.661C8.145 16.622 9.21 17.406 10.589 18.012C11.967 18.618 13.616 19.148 15.537 19.608L18.795 20.423C21.342 20.965 23.629 21.708 25.656 22.647C27.681 23.586 29.298 24.861 30.51 26.466C31.722 28.075 32.328 30.174 32.328 32.764C32.328 35.354 31.679 37.608 30.384 39.528C29.089 41.452 27.263 42.945 24.903 44.009C22.543 45.075 19.776 45.608 16.603 45.608Z"
            fill="currentColor"
          />
          <path
            d="M37.864 14.096H42.814V44.73H37.864V14.096ZM40.371 9.33401C39.283 9.33401 38.374 8.97902 37.644 8.27002C36.914 7.55902 36.549 6.66201 36.549 5.57401C36.549 4.44801 36.914 3.52999 37.644 2.81799C38.374 2.10899 39.283 1.755 40.371 1.755C41.455 1.755 42.353 2.10899 43.064 2.81799C43.774 3.52999 44.128 4.44801 44.128 5.57401C44.128 6.66201 43.774 7.55902 43.064 8.27002C42.353 8.97902 41.455 9.33401 40.371 9.33401Z"
            fill="currentColor"
          />
          <path
            d="M56.1885 44.7295C54.4345 44.7295 53.0885 44.2505 52.1495 43.2875C51.2085 42.3275 50.7385 41.0125 50.7385 39.3405V3.1935H55.6885V14.0955H65.7125V18.4175H55.6885V38.5895C55.6885 39.8435 56.2935 40.4705 57.5045 40.4705H64.3345V44.7295H56.1885Z"
            fill="currentColor"
          />
          <path
            d="M72.716 26.624H92.639C92.471 23.786 91.5 21.571 89.725 19.984C87.95 18.397 85.686 17.603 82.929 17.603C80.13 17.603 77.832 18.397 76.038 19.984C74.241 21.571 73.134 23.786 72.716 26.624ZM83.241 45.608C80.15 45.608 77.435 44.948 75.097 43.633C72.758 42.318 70.941 40.47 69.647 38.09C68.352 35.709 67.704 32.952 67.704 29.82V29.069C67.704 25.894 68.352 23.116 69.647 20.735C70.941 18.354 72.737 16.506 75.035 15.192C77.331 13.877 79.962 13.217 82.929 13.217C85.81 13.217 88.357 13.835 90.572 15.068C92.784 16.297 94.519 18.063 95.771 20.36C97.024 22.659 97.651 25.352 97.651 28.442V30.695H72.654C72.78 33.997 73.844 36.575 75.85 38.435C77.854 40.292 80.36 41.222 83.367 41.222C85.998 41.222 88.024 40.616 89.443 39.404C90.862 38.193 91.95 36.754 92.701 35.082L96.962 37.15C96.335 38.444 95.468 39.74 94.361 41.034C93.254 42.327 91.814 43.415 90.038 44.29C88.263 45.169 85.998 45.608 83.241 45.608Z"
            fill="currentColor"
          />
          <path
            d="M104.039 14.096H108.989V44.73H104.039V14.096ZM106.546 9.33401C105.458 9.33401 104.55 8.97902 103.82 8.27002C103.09 7.55902 102.725 6.66201 102.725 5.57401C102.725 4.44801 103.09 3.52999 103.82 2.81799C104.55 2.10899 105.458 1.755 106.546 1.755C107.63 1.755 108.529 2.10899 109.239 2.81799C109.949 3.52999 110.304 4.44801 110.304 5.57401C110.304 6.66201 109.949 7.55902 109.239 8.27002C108.529 8.97902 107.63 9.33401 106.546 9.33401Z"
            fill="currentColor"
          />
          <path
            d="M116.775 44.7295V14.0955H121.599V19.2965H122.476C123.144 17.8335 124.283 16.5065 125.89 15.3155C127.498 14.1255 129.869 13.5315 133.001 13.5315C135.297 13.5315 137.355 14.0135 139.171 14.9735C140.989 15.9335 142.44 17.3515 143.526 19.2325C144.612 21.1105 145.156 23.4315 145.156 26.1845V44.7295H140.206V26.5605C140.206 23.5125 139.442 21.2985 137.919 19.9205C136.394 18.5425 134.337 17.8545 131.748 17.8545C128.783 17.8545 126.37 18.8145 124.512 20.7355C122.653 22.6585 121.725 25.5185 121.725 29.3165V44.7295H116.775Z"
            fill="currentColor"
          />
          <path
            d="M164.561 45.608C160.759 45.608 157.553 44.73 154.943 42.976C152.334 41.222 150.757 38.444 150.215 34.642L154.913 33.579C155.246 35.584 155.873 37.16 156.793 38.308C157.71 39.459 158.848 40.271 160.207 40.752C161.564 41.231 163.015 41.473 164.561 41.473C166.857 41.473 168.675 41.013 170.011 40.095C171.346 39.174 172.016 37.944 172.016 36.396C172.016 34.812 171.38 33.694 170.104 33.046C168.829 32.398 167.087 31.868 164.873 31.45L162.306 31.01C160.341 30.677 158.547 30.153 156.917 29.444C155.289 28.732 153.994 27.772 153.033 26.56C152.072 25.352 151.591 23.828 151.591 21.989C151.591 19.233 152.658 17.082 154.787 15.537C156.917 13.992 159.737 13.217 163.245 13.217C166.712 13.217 169.52 14.001 171.671 15.567C173.821 17.134 175.212 19.336 175.836 22.177L171.201 23.368C170.825 21.153 169.926 19.599 168.505 18.7C167.087 17.803 165.333 17.352 163.245 17.352C161.156 17.352 159.507 17.739 158.295 18.512C157.083 19.284 156.479 20.423 156.479 21.926C156.479 23.389 157.053 24.464 158.201 25.152C159.349 25.839 160.885 26.354 162.806 26.688L165.374 27.127C167.548 27.503 169.499 28.014 171.231 28.66C172.964 29.308 174.342 30.238 175.366 31.45C176.39 32.661 176.902 34.245 176.902 36.208C176.902 39.216 175.784 41.534 173.552 43.163C171.316 44.793 168.317 45.608 164.561 45.608Z"
            fill="currentColor"
          />
          <path
            d="M197.984 41.2217C201.159 41.2217 203.736 40.2097 205.721 38.1837C207.705 36.1567 208.695 33.3277 208.695 29.6927V29.1287C208.695 25.4967 207.705 22.6677 205.721 20.6417C203.736 18.6177 201.159 17.6027 197.984 17.6027C194.852 17.6027 192.271 18.6177 190.247 20.6417C188.221 22.6677 187.209 25.4967 187.209 29.1287V29.6927C187.209 33.3277 188.221 36.1567 190.247 38.1837C192.271 40.2097 194.852 41.2217 197.984 41.2217ZM182.323 57.2587V14.0957H187.146V19.1687H188.024C188.815 17.6247 190.099 16.2457 191.874 15.0347C193.649 13.8257 196.124 13.2167 199.299 13.2167C201.931 13.2167 204.342 13.8557 206.535 15.1277C208.729 16.4037 210.482 18.2087 211.797 20.5477C213.115 22.8887 213.772 25.7057 213.772 29.0047V29.8197C213.772 33.0757 213.124 35.8967 211.83 38.2777C210.534 40.6587 208.789 42.4727 206.599 43.7267C204.406 44.9807 201.97 45.6077 199.299 45.6077C197.169 45.6077 195.364 45.3237 193.879 44.7597C192.398 44.1967 191.196 43.4757 190.278 42.5997C189.36 41.7217 188.648 40.8247 188.148 39.9067H187.27V57.2587H182.323Z"
            fill="currentColor"
          />
          <path
            d="M220.161 14.096H225.108V44.73H220.161V14.096ZM222.666 9.33401C221.578 9.33401 220.67 8.97902 219.94 8.27002C219.209 7.55902 218.843 6.66201 218.843 5.57401C218.843 4.44801 219.209 3.52999 219.94 2.81799C220.67 2.10899 221.578 1.755 222.666 1.755C223.75 1.755 224.65 2.10899 225.359 2.81799C226.068 3.52999 226.425 4.44801 226.425 5.57401C226.425 6.66201 226.068 7.55902 225.359 8.27002C224.65 8.97902 223.75 9.33401 222.666 9.33401Z"
            fill="currentColor"
          />
          <path
            d="M233.005 44.7295V14.0955H237.827V17.8545H238.706C239.248 16.5185 240.084 15.5375 241.211 14.9105C242.338 14.2835 243.819 13.9715 245.658 13.9715H249.293V18.4815H245.346C243.132 18.4815 241.344 19.0965 239.99 20.3295C238.63 21.5625 237.952 23.4705 237.952 26.0605V44.7295H233.005Z"
            fill="currentColor"
          />
          <path
            d="M254.982 26.6027H274.902C274.735 23.7647 273.766 21.5497 271.991 19.9627C270.216 18.3757 267.95 17.5817 265.193 17.5817C262.394 17.5817 260.095 18.3757 258.302 19.9627C256.506 21.5497 255.397 23.7647 254.982 26.6027ZM265.505 45.5867C262.416 45.5867 259.701 44.9267 257.363 43.6117C255.021 42.2967 253.207 40.4487 251.91 38.0687C250.617 35.6877 249.969 32.9307 249.969 29.7987V29.0477C249.969 25.8727 250.617 23.0947 251.91 20.7137C253.207 18.3327 255.003 16.4847 257.299 15.1707C259.595 13.8557 262.228 13.1957 265.193 13.1957C268.074 13.1957 270.622 13.8137 272.836 15.0467C275.05 16.2757 276.783 18.0417 278.037 20.3387C279.288 22.6377 279.915 25.3307 279.915 28.4207V30.6737H254.918C255.043 33.9757 256.109 36.5537 258.114 38.4137C260.12 40.2707 262.625 41.2007 265.633 41.2007C268.262 41.2007 270.289 40.5947 271.709 39.3827C273.127 38.1717 274.214 36.7327 274.966 35.0607L279.225 37.1287C278.601 38.4227 277.731 39.7187 276.626 41.0127C275.52 42.3057 274.078 43.3937 272.303 44.2687C270.528 45.1477 268.262 45.5867 265.505 45.5867Z"
            fill="currentColor"
          />
        </svg>
      </motion.div>
    </div>
  );
};

export default Logotype;
