"use client";

import { Button } from "@/app/components/ui/button";
import { useHeaderContext } from "@/contexts/HeaderContext";
import { useMenuContext } from "@/contexts/MenuContext";
import clsx from "clsx";
import { Menu, X } from "lucide-react";
import { forwardRef } from "react";

type MenuToggleProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

const MenuToggle = forwardRef<HTMLButtonElement, MenuToggleProps>(
  (props, forwardedRef) => {
    const { transparent } = useHeaderContext();
    const { open } = useMenuContext();

    return (
      <Button
        {...props}
        className={clsx(
          "MenuToggle",
          "flex items-center justify-center lg:hidden fixed z-60 right-4 top-3",
          {
            "text-zinc-50": transparent,
            "text-zinc-900": !transparent || open,
          }
        )}
        ref={forwardedRef}
        aria-label="Toggle menu"
        variant="ghost"
        size="icon"
      >
        {!open && <Menu className={clsx("size-6 stroke-[1.5]")} />}
        {!!open && <X className={clsx("size-6 stroke-[1.5]")} />}
      </Button>
    );
  }
);

MenuToggle.displayName = "MenuToggle";

export default MenuToggle;
