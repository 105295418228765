import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-full text-base lg:text-sm transition-colors focus-visible:outline-none focus-visible:ring-1 disabled:pointer-events-none disabled:opacity-50 dark:focus-visible:ring-zinc-300 cursor-pointer max-w-md",
  {
    variants: {
      variant: {
        default:
          "bg-zinc-900 text-zinc-50 hover:bg-zinc-900/90 dark:bg-zinc-50 dark:text-zinc-900 dark:hover:bg-zinc-50/90 focus-visible:ring-zinc-950",
        destructive:
          "bg-red-500 text-zinc-50 hover:bg-red-500/90 dark:bg-red-900 dark:text-zinc-50 dark:hover:bg-red-900/90 focus-visible:ring-red-950",
        outline:
          "border border-zinc-200 bg-white hover:bg-zinc-100 hover:text-zinc-900 dark:border-zinc-800 dark:bg-zinc-950 dark:hover:bg-zinc-800 dark:hover:text-zinc-50 focus-visible:ring-zinc-950",
        secondary:
          "bg-zinc-100 text-zinc-900 hover:bg-zinc-200/80 dark:bg-zinc-800 dark:text-zinc-50 dark:hover:bg-zinc-800/80 focus-visible:ring-zinc-300",
        ghost:
          "hover:bg-zinc-100 hover:text-zinc-900 dark:hover:bg-zinc-800 dark:hover:text-zinc-50 focus-visible:ring-zinc-300",
        destructiveGhost:
          "hover:bg-red-100 hover:text-red-900 dark:hover:bg-red-800 dark:hover:text-red-50 text-red-700 focus-visible:ring-red-500",
        link: "text-zinc-900 underline-offset-4 hover:underline dark:text-zinc-50 decoration-zinc-500 focus-visible:ring-zinc-300 justify-start",
        circular: "rounded-full focus-visible:ring-zinc-300",
      },
      size: {
        text: "px-0 h-auto",
        default: "h-10 px-4",
        responsive: "h-8 px-4 text-xs lg:text-sm lg:h-10 lg:px-4",
        sm: "h-8 px-4 text-sm lg:text-xs",
        xs: "text-xs px-2 py-1",
        lg: "h-12 px-8",
        responsiveIcon: "size-8 lg:size-10",
        icon: "size-10",
        iconSm: "size-8",
        iconLg: "size-12",
      },
      rounded: {
        true: "rounded-full",
        false: "rounded",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      rounded: true,
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, rounded, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, rounded, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
