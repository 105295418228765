"use client";

import { Button } from "@/app/components/ui/button";
import { useSearchContext } from "@/contexts/SearchContext";
import clsx from "clsx";
import { Search, X } from "lucide-react";

const SearchToggle = () => {
  const { isOpen, setIsOpen } = useSearchContext();

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const Icon = isOpen ? X : Search;

  return (
    <Button
      className={clsx("SearchToggle")}
      onClick={handleClick}
      aria-label="Toggle search"
      variant="ghost"
      size="icon"
    >
      <Icon className={clsx("size-5 stroke-[1.5]")} aria-hidden />
    </Button>
  );
};

export default SearchToggle;
